import React from 'react';
import ReactNativeTab from './_code-reactnative';
import { useStaticQuery, graphql } from 'gatsby';
import WebTab from './_code-web';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query spaceWebResources {
    allSpacesYaml {
      edges {
        node {
          spaces {
            name
            value
            css_var
            rn_var
          }
        }
      }
    }
  }
  `);
  return (
    <PageWithSubNav pageType="design" title="Space" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Space"
      />

      <PlatformTabs
        pageType="resources"
        platforms={{
          web: <WebTab data={data} />,
          reactnative: <ReactNativeTab data={data} />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
