import React from 'react';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  Link,
  Paragraph,
  PlatformTab,
  Section,
  CodeSnippet,
} from '../../../../components';

const ReactNativeTab = ({ data }) => {
  return (
    <PlatformTab>
      <Section title="UniformSpace Object">
        <Paragraph>
          The <code>UniformSpace</code> object provides
          easy referencing to default Uniform space values. To use the object,
          simply import it like so:
          <CodeSnippet
            disableCodeEditing
            platform="react-native"
            code="import { UniformSpace } from '@hudl/rn-uniform';"
          />
        </Paragraph>
        <Paragraph>
          Now you can access any of the values listed below by using{' '}
          <code>UniformSpace.value</code>.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Value</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allSpacesYaml.edges[0].node.spaces.map(item => {
              return (
                <DataTableRow layout="thirds" key={item.css_var}>
                  <DataTableValueBlock>{item.name}</DataTableValueBlock>
                  <DataTableValueBlock>{item.value}</DataTableValueBlock>
                  <DataTableCodeBlock>{item.rn_var}</DataTableCodeBlock>
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTable>
      </Section>
      <Section>
        <Paragraph>
          See the{' '}
          <Link href="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/src#space">
            Uniform README
          </Link>{' '}
          for more information.
        </Paragraph>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
